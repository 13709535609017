// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&display=swap");

$family-sans-serif: "Inter", sans-serif;
$primary: #fa8128;
$grey: #f6f6f6;
$grey-light: #eeeeee;
$grey-dark: #898989;
$black: #000;

body {
}
.layout-container {
	max-width: 1240px;
	background-color: $grey;
	margin: 0 auto;
	height: 100vh;
}
@import "../node_modules/bulma/bulma.sass";
